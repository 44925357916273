.smartbanner {
    height: fit-content !important;
    position: static !important;
    @apply gap-x-1;
}

.smartbanner__info {
    height: fit-content !important;
    padding: .5rem 0;
}
