.form-error {
    .ng-invalid {
        &.form-control {
            @apply border border-[#ff0000];

            ~ .error-message {
                @apply opacity-100;
            }
        }
    }
}

.error-message {
    @apply text-[#ff0000] opacity-0;

    &.show {
        @apply opacity-100;
    }
}

.form-label {
    @apply text-sm md:text-base text-gsc-grey mb-2;
}

.form-input {
    @apply mb-3;
}

.form-control {
    @apply p-3 border border-gsc-grey-lighter rounded-md bg-transparent;

    &:focus {
        @apply border-gsc-main-yellow shadow-none outline-none;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
}

.invoice-form input { 
    @apply bg-gsc-form-blue px-[0.625rem] py-[1rem] font-montRegular md:text-[1rem] text-[0.875rem] overflow-x-auto;
}

.invoice-form input:-webkit-autofill, .invoice-form input:-internal-autofill-selected {
    -webkit-text-fill-color: black;
    background-color: #F4F9FC;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-background-clip: unset;
}

.currency-selection>input:-internal-autofill-selected, .currency-selection>input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
    background-color: #F4F9FC;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-background-clip: unset;
}

.invoice-form textarea {
    resize: none;
    @apply bg-gsc-form-blue px-[0.625rem] py-[1rem];
}

.invoice-form textarea:focus {
    outline: none !important;
}

.invoice-form mat-form-field {
    @apply bg-gsc-form-blue px-[0.625rem] py-[0.5rem] lg:w-[25%] xl:w-[14%] w-[35%];
}

.invoice-form .transaction-input {
    @apply bg-gsc-form-blue py-0;
}

.invoice-form .mat-form-field-wrapper {
    padding: 0 !important;
    width: 100%;
}

.invoice-form mat-select {
    margin-left: 0.5rem !important;
}

.invoice-form .mat-select-panel-wrap {
    margin-top: 3.5rem !important;
}

.invoice-form .mat-select-value-text {
    color: black;
}

.invoice-form .mat-select-value {
    @apply relative bottom-[0.3rem] md:text-[1rem] text-[0.875rem];
}

.country-code {
    mat-form-field {
        padding: 0.2rem 0.5rem 0 0;
    }

    .mat-form-field-flex {
        display: flex;
        align-items: center;
    }
}

.currency-selection.mat-select-panel {
    margin-top: 2.5rem !important;
    position: fixed; 
}

.currency-selection .mat-option.mat-active {
    background: none;
}

.currency-selection .currency-selection-hide.mat-option {
    display: none !important;
}

.currency-selection .currency-selection-show.mat-option {
    display: block !important;
}

.currency-selection .mat-option .mat-option-text {
    border: 0px solid black !important;
}

.address-selection mat-form-field {
    width: 100%;
}

.flex-input {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
// .invoice-form mat-select {
//     @apply bg-gsc-form-blue px-[0.625rem] py-[1rem] w-[15%];
// }
