/* You can add global styles to this file, and also import other style files */
@import "app/shared/styles/material.scss";
@import "app/shared/styles/buttons.scss";
@import "app/shared/styles/forms.scss";
@import "app/shared/styles/customs.scss";
@import "app/shared/styles/smart-app-banner.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
      font-family: mont-black;
      font-weight: 900;
      src: url(../src/assets/fonts/Montserrat-Black.ttf);
    }
    @font-face {
      font-family: mont-bold;
      font-weight: 700;
      src: url(../src/assets/fonts/Montserrat-Bold.ttf);
    }
    @font-face {
        font-family: mont-extrabold;
        font-weight: 800;
        src: url(../src/assets/fonts/Montserrat-ExtraBold.ttf);
    }
    @font-face {
      font-family: mont-medium;
      font-weight: 500;
      src: url(../src/assets/fonts/Montserrat-Medium.ttf);
    }
    @font-face {
      font-family: mont-regular;
      font-weight: 400;
      src: url(../src/assets/fonts/Montserrat-Regular.ttf);
    }
    @font-face {
      font-family: mont-semibold;
      font-weight: 600;
      src: url(../src/assets/fonts/Montserrat-SemiBold.ttf);
    }
     @font-face {
      font-family: poppins-bold;
      font-weight: 700;
      src: url(../src/assets/fonts/Poppins-Bold_0.ttf);
    }
    @font-face {
      font-family: poppins-light;
      font-weight: 300;
      src: url(../src/assets/fonts/Poppins-Light_0.ttf);
    }
    @font-face {
      font-family: poppins-medium;
      font-weight: 500;
      src: url(../src/assets/fonts/Poppins-Medium_0.ttf);
    }
    @font-face {
      font-family: poppins-regular;
      font-weight: 400;
      src: url(../src/assets/fonts/Poppins-Regular_0.ttf);
    }
    @font-face {
      font-family: poppins-semibold;
      font-weight: 600;
      src: url(../src/assets/fonts/Poppins-SemiBold_0.ttf);
    }
    @font-face {
      font-family: poppins-thin;
      font-weight: 100;
      src: url(../src/assets/fonts/Poppins-Thin_0.ttf);
    }
    @font-face {
      font-family: phenomena-regular;
      font-weight: 400;
      src: url(../src/assets/fonts/Phenomena-Regular.otf);
    }
  }


html,
body {
    // min-height: 100vh;
}

body {
    margin: 0;
    @apply font-montRegular;
}

.gsc-container {
    @apply max-w-screen-2xl mx-auto;
}
