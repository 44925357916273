.qr-rewards-icon {
    @apply bg-gsc-icon-gsc-rewards gsc-icon-0 w-[200px] h-11;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.gsc-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #fff000 #535353;
}

.gsc-scrollbar-aurum {
    scrollbar-width: thin;
    scrollbar-color: #ECD261 #535353;
}

.gsc-scrollbar-velvet {
    scrollbar-width: thin;
    scrollbar-color: #04335D #535353;
}

.gsc-scrollbar-review-summary-rewards {
    scrollbar-width: thin;
    scrollbar-color: #000000 #2F2F2F;
}

/* Chrome, Edge, and Safari */
.gsc-scrollbar::-webkit-scrollbar, .gsc-scrollbar-review-summary-rewards::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.gsc-scrollbar-review-summary-rewards::-webkit-scrollbar-track {
    background: #626262;
    border-radius: 20px;
}

.gsc-scrollbar::-webkit-scrollbar-track {
    background: #535353;
    border-radius: 10px;
}


.gsc-scrollbar::-webkit-scrollbar-thumb {
    background-color: #fff000;
    border-radius: 10px;
    border: 2px solid #fff000;
}

.gsc-scrollbar-aurum::-webkit-scrollbar-thumb {
    background-color: #ECD261;
    border-radius: 10px;
    border: 2px solid #ECD261;
}

.gsc-scrollbar-velvet::-webkit-scrollbar-thumb {
    background-color: #04335D;
    border-radius: 10px;
    border: 2px solid #04335D;
}

// Invisible scrollbar for seat plan
.drag-scroll-content::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.drag-scroll-content {
    scrollbar-width: none;
}

.rewards-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 4.6em;
    max-height: 4.6em;
    line-height: 1.3em;
  }
