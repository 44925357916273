@layer components {
    .btn {
        @apply min-h-[3.125rem] font-bold text-black capitalize rounded-md hover:shadow-md p-3 text-sm lg:text-base transition-all duration-300 disabled:opacity-60 disabled:hover:shadow-none;

        &.agmo-main-yellow-btn {
            @apply bg-gsc-main-yellow;
        }

        &.agmo-aurum-yellow-btn {
            @apply bg-gsc-aurum-yellow;
        }

        &.agmo-velvet-blue-btn {
            @apply bg-gsc-velvet-blue text-gsc-velvet-orange;
        }

        &.agmo-grey-btn {
            @apply bg-gsc-grey-dark;
        }

        &.btn-confirm {
            @apply text-sm md:text-base bg-gsc-main-yellow text-opacity-80 shadow-sm rounded-md outline-none opacity-90 hover:opacity-100 hover:text-opacity-100;

            &.btn-aurum {
                @apply bg-gsc-aurum-yellow;
            }

            &.btn-velvet {
                @apply bg-gsc-velvet-blue text-gsc-velvet-orange;
            }
        }

        &.btn-cancel {
            @apply text-sm md:text-base bg-gsc-grey text-opacity-80 shadow-sm rounded-md outline-none opacity-90 hover:opacity-100 hover:text-opacity-100;
        }
    }
}
